import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@auth0/auth0-vue'
import { userStatusGuard } from '@/router/userStatusGuard'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/landing/LoginView.vue')
    },
    {
      path: '/callback',
      name: 'auth_callback',
      component: () => import('../views/AuthCallBackView.vue')
    },
    {
      path: '/app',
      name: 'app',
      component: () => import('../views/DashBoard.vue'),
      beforeEnter: [authGuard, userStatusGuard],
      children: [
        {
          path: '',
          name: 'default-redirect',
          redirect: '/app/publishers'
        },
        {
          path: 'publishers',
          name: 'publisher-listing',
          component: () => import('../views/publisher/PublisherListingView.vue'),
          beforeEnter: [authGuard, userStatusGuard],
          children: [
            /*            {
                                      path: '',
                                      name: 'pub-default-redirect',
                                      redirect: '/app/publishers/digital/display'
                                    },
                        */
            {
              path: 'invalid',
              name: 'publisher-invalid',
              component: () => import('../views/publisher/PubInvalidView.vue'),
              beforeEnter: [authGuard]
            },
            {
              path: ':ad_type/:category',
              name: 'pub-list-ad_type-category',
              props: true,
              component: () => import('../views/publisher/PubListAdTypeCategoryView.vue'),
              beforeEnter: [authGuard]
            }
          ]
        },
        {
          path: 'card',
          name: 'card',
          component: () => import('../views/publisher/PublisherCardAddition.vue'),
          beforeEnter: [authGuard, userStatusGuard]
        },
        {
          path: 'cart',
          name: 'cart',
          component: () => import('../views/cart/CartView.vue'),
          beforeEnter: [authGuard, userStatusGuard]
        },
        {
          path: 'purchase/:orderid',
          name: 'purchase-order',
          component: () => import('../views/purchase/PurchaseView.vue'),
          beforeEnter: [authGuard, userStatusGuard]
        },
        {
          path: 'purchase',
          name: 'purchase',
          component: () => import('../views/purchase/PurchaseView.vue'),
          beforeEnter: [authGuard, userStatusGuard]
        },
        {
          path: 'campaign',
          name: 'campaign',
          component: () => import('../views/campaign/CampaignView.vue'),
          beforeEnter: [authGuard, userStatusGuard]
        }
      ]
    }
  ]
})

export default router
